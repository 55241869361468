'use strict';

var QaDetailController = /*@ngInject*/ function(
  $state,
  $stateParams,
  JobService,
  META_STATUS,
  SearchService,
  Session,
  NotificationService,
  OutreachService,
  RouterTracker,
  ReactNavigationService,
  APP_DATA
) {
  var _self = this;
  _self.error = {
    active: false,
    data: {
      type: 'error',
      heading: {
        title: 'Job Not Found',
        copy: 'This job does not exist, or is no longer in the pipeline.'
      },
      cta: {
        primary: 'Go To Search'
      }
    },
    onSubmit: function() {
      $state.go('search');
    }
  };

  _self.handleReviewCompletion = function($event) {
    var searchParams = {
      term: '*',
      page: 0,
      size: 50,
      sort: 'priority|desc,tasks.startedOn|asc',
      filters:
        'status=' +
        META_STATUS.qa +
        ',processedForSeconds=60' +
        ',assignees=' +
        Session.get('userName')
    };

    _self.working = true;

    SearchService.search(searchParams)
      .then(function(response) {
        // Filter self out from response
        // (The search service updates asyncronously, so it may still appear as "In Image QA")
        var jobs = response.data.results.filter(function(job) {
          return job.id !== $event.job.id;
        });

        // If there is another QA job assignee to the user, load that job data
        // Otherwise, redirect to QA queue
        if (jobs.length) {
          $state.go(
            'qa',
            {
              id: jobs[0].id,
              assetJobId: undefined
            },
            {
              location: 'replace'
            }
          );
        } else {
          ReactNavigationService.goToQAQueue();
        }
      })
      .catch(function() {
        ReactNavigationService.goToQAQueue();
      })
      .finally(function() {
        NotificationService.push($event.notification, $event.job);
        _self.working = false;
      });
  };

  _self.initiateProblemSolve = function() {
    _self.showProblemModal = true;
  };

  _self.onMoveToProblemSolve = function() {
    ReactNavigationService.goToQAQueue();
  };

  _self.closeQADetail = function() {
    ReactNavigationService.goToQAQueue();
  };

  _self.updateNotes = function($event) {
    _self.data = $event.job;
  };

  _self.init = function() {
    _self.working = true;

    var previousRoute = RouterTracker.getPreviousState();

    JobService.getJobDetail($stateParams.id, {
      RequestUpdatedProductInfo: true
    })
      .then(function(result) {
        _self.data = result.data;

        //If the job has review comments and is in Image QA, then bounce to Product Review
        _self.hasReviewComments = false;
        if (_self.data.notes && _self.data.notes.length > 0) {
          _self.hasReviewComments = _self.data.notes.filter(function(a) {
            return a.noteType === 'Product Review';
          });
        }
        var outreachState = OutreachService.getState();

        if (
          _self.data.status === 'In Image QA' &&
          _self.hasReviewComments.length > 0 &&
          previousRoute.route.name !== 'outreach'
        ) {
          OutreachService.setState('image_qa_review');
          $state.go('outreach', {
            id: _self.data.id
          });
        }
      })
      .catch(function() {
        _self.error.active = true;
      })
      .finally(function() {
        _self.working = false;
      });
  };

  _self.init();
};

module.exports = QaDetailController;
