'use strict';

var angular = require('angular');

var qaStatus = {
  bindings: {
    job: '<',
    relatedJobs: '<',
    onChange: '&'
  },
  require: {
    parent: '^^imageViewer'
  },
  controller: /*@ngInject*/ function(
    $interpolate,
    META_STATUS,
    AuthService,
    JobFormService,
    JobService,
    StatusService,
    $state,
    $window,
    APP_DATA
  ) {
    var _self = this,
      views = {
        related: 'Related {{string}} ({{length}})'
      };
    _self.error = {};

    _self.getJobStatusClass = function(status) {
      var className;

      switch (status) {
        case META_STATUS.jobPending:
        case META_STATUS.inProgress:
          className = 'active';
          break;
        case META_STATUS.problemSolve:
          className = 'problem-solve';
          break;
        default:
          className = 'inactive';
      }

      return className;
    };

    _self.getAssetAssignee = function(model) {
      if (model.status === META_STATUS.post && model.tasks.length) {
        return model.tasks[0].assignee || null;
      }
      return null;
    };

    _self.isAdmin = AuthService.isAdmin();

    _self.resetError = function() {
      _self.error = {};
    };

    _self.viewMerch = function(merchId) {
      var url = $state.href('merch-management.views', {
        view: 'manage',
        q: merchId,
      });
      $window.open(url, '_blank');
    }

    _self.$onChanges = function(changes) {
      var validAsset = false;

      if (changes.relatedJobs) {
        if (_self.relatedJobs) {
          _self.relatedJobs = angular.copy(_self.relatedJobs);
          _self.relatedStr = $interpolate(views.related)({
            length: _self.relatedJobs.length,
            string: _self.relatedJobs.length !== 1 ? 'jobs' : 'job'
          });
        }
      }

      if (changes.job) {
        if (!_self.job) {
          return;
        }
        _self.job = angular.copy(_self.job);

        _self.jobStatusClass = _self.getJobStatusClass(_self.job.status);
        _self.assetAssignee = _self.getAssetAssignee(_self.job);
        _self.assigneeSlot = StatusService.setAssigneeSlotValue(_self.job);

        if(_self.job.merchType && _self.job.shootType && _self.job.shootType.code) {
          JobService.getDeliveryDestinations(_self.job.merchType, _self.job.shootType.code,
            function (deliveryDestination) {
              _self.job.deliveryDestination = deliveryDestination;
            },
            function (error) {
              _self.job.deliveryDestination = 'ERROR';
            });
        } else {
          _self.job.deliveryDestination = 'N/A';
        }

        _self.job.assetJobs.some(function(assetJob) {
          validAsset = _self.job.status === META_STATUS.post;
          return validAsset;
        });

        // View permissions
        _self.canSeeConfidential = _self.job.confidential && _self.isAdmin;
        // reset form data
        _self.formData = angular.copy(JobFormService.formData);
        JobFormService.setFormData(
          _self.job,
          _self.formData,
          false,
          _self.error
        );
      }
    };
  },
  templateUrl: 'templates/partials/detail/qaStatus.tpl.html'
};

module.exports = qaStatus;
