'use strict';

var OutreachDetailController = /*@ngInject*/ function(
  $state,
  $stateParams,
  JobService,
  META_STATUS,
  SearchService,
  Session,
  NotificationService,
  OutreachService,
  ReactNavigationService,
  APP_DATA
) {
  var _self = this;

  _self.error = {
    active: false,
    data: {
      type: 'error',
      heading: {
        title: 'Job Not Found',
        copy: 'This job does not exist, or is no longer in the pipeline.'
      },
      cta: {
        primary: 'Go To Search'
      }
    },
    onSubmit: function() {
      $state.go('search');
    }
  };

  _self.handleReviewCompletion = function($event) {
    var searchParams = {
      term: '*',
      page: 0,
      size: 50,
      sort: 'priority|desc,tasks.startedOn|asc',
      filters:
        'status=' +
        META_STATUS.qa +
        ',processedForSeconds=60' +
        ',assignees=' +
        Session.get('userName')
    };

    _self.working = true;
    SearchService.search(searchParams)
      .then(function(response) {
        // Filter self out from response
        // (The search service updates asyncronously, so it may still appear as "In Image QA")
        var jobs = response.data.results.filter(function(job) {
          return job.id !== $event.job.id;
        });

        // If there is another QA job assignee to the user, load that job data
        // Otherwise, redirect to QA queue
        if (jobs.length) {
          $state.go(
            'qa',
            {
              id: jobs[0].id,
              assetJobId: undefined
            },
            {
              location: 'replace'
            }
          );
        } else {
          ReactNavigationService.goToQAQueue();
        }
      })
      .catch(function() {
        ReactNavigationService.goToQAQueue();
      })
      .finally(function() {
        NotificationService.push($event.notification, $event.job);
        _self.working = false;
      });
  };

  _self.exitOutreach = function() {
    switch (OutreachService.getState()) {
      case 'image_qa':
        ReactNavigationService.goToQAQueue();
        break;
      case 'image_qa_review':
        ReactNavigationService.goToQAQueue();
        break;
      case 'outreachQueue':
        $state.go('outreachQueue.outreach');
        break;
      default:
        $state.go('search');
        break;
    }
  };

  _self.initiateProblemSolve = function() {
    _self.showProblemModal = true;
  };

  _self.onMoveToProblemSolve = function() {
    ReactNavigationService.goToQAQueue();
  };

  _self.init = function() {
    _self.working = true;

    JobService.getJobDetail($stateParams.id, {
      RequestUpdatedProductInfo: true
    })
      .then(function(result) {
        _self.data = result.data;
      })
      .catch(function() {
        _self.error.active = true;
      })
      .finally(function() {
        _self.working = false;
      });
  };

  _self.$onDestroy = function() {
    //Reset the state so it doesn't get carried around
    OutreachService.setState(null);
  };

  _self.init();
};

module.exports = OutreachDetailController;
