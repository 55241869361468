'use strict';

var angular = require('angular');

var EventLoggerService = /*@ngInject*/ function(Session, $window) {
  var _self = this;

  //Necessary for testing
  _self.getPort = function() {
    return $window.location.port;
  };

  // Log a custom event in New Relic
  // eventName: <string> name of the event to track
  // eventAttributes: <json> object containing any supplemental data to record along with the event

  _self.track = function(name, params, conditional) {
    // The event will only be tracked if the conditional is truthy and we are not on a port (local work or Jenkins)
    if (!conditional || _self.getPort()) {
      if (_self.getPort()) {
        console.info('suppressed tracking event', {
          name: name,
          params: params,
          conditional: conditional
        });
      }
      return;
    }

    var USER_ROLES = Session.get ? Session.get().roles : undefined;

    var defaultParams = {
      USER_ROLES: USER_ROLES
    };
    var payload = Object.assign(defaultParams, params);

    console.info('track event', payload);

    NREUM.addPageAction(name, payload);
  };
  return _self;
};

module.exports = EventLoggerService;
