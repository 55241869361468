'use strict';

var REACT_BASE_PATH = '/v2/web/index.html';
var REACT_LAST_ROUTE_STORAGE_KEY = 'react_current_route';

var ReactNavigationService = /*@ngInject*/ function($state) {
  this.goToQAQueue = function() {
    const reactRoute = sessionStorage.getItem(REACT_LAST_ROUTE_STORAGE_KEY);

    if (!reactRoute) {
      $state.go('queue.qa');

      return;
    }

    window.open(`${REACT_BASE_PATH}#${reactRoute}`, '_self');
  };
};

module.exports = ReactNavigationService;
