'use strict';

const VIRTUAL_PHOTO = 'Virtual Photo';
const LINE_ART = 'Line Art';

const PRIMARY_LINE_ART = ['LASFH001', 'LASRZ001', 'VPSFH001'];
const SECONDARY_LINE_ART = ['LASBH001', 'VPSBH001'];
const PRIMARY_VIRTUAL_PHOTO = ['VPSFH001', 'VPSRH001', 'VPSRH000', 'VPCYZ001', 'VSSRH001'];
const SECONDARY_VIRTUAL_PHOTO = ['VPSBH001', 'VPSUH000', 'VPSLH001', 'VPSTH001', 'VPSBH000'];

var angular = require('angular');
var moment = require('moment');

var TransferService = /*@ngInject*/ function(
  $rootScope,
  $q,
  $log,
  $http,
  REST_URL,
  AURORA_REST_URL,
  DOWNLOAD_URL,
  DownloadStatusService,
  JobService,
  $window
) {
  var _self = this;

  var reset = function() {
    _self.data.working = false;
    _self.data.length = null;
    _self.data.title = null;
  };

  _self.getAttachmentUrl = function(jobId, filename) {
    filename = filename || '';

    return [REST_URL.jobs, jobId, 'attachments', filename].join('/');
  };

  _self.generateFile = function(response, filename) {
    var headers = response.headers();
    var blob = new Blob([response.data], { type: headers['content-type'] });
    var link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  _self.sendDownloadRequest = function(payload) {
    payload.forEach(p => {
      p.includeImageTypes = p.includeImageTypes || [];
    });
    payload.forEach(p => {
      p.includeCropTypes = p.includeCropTypes || [];
    });

    return $http.post(`${REST_URL.base}/files/metajob/download`, payload);
  };

  _self.downloadAssets = function(filenames, jobList) {
    var deferred = $q.defer();
    if (!angular.isArray(filenames)) {
      filenames = [filenames];
    }
    var zipName = filenames.length === 1 ? filenames[0] : 'Assets';
    _self.data.working = true;
    _self.data.length = filenames.length;
    _self.data.title = 'Downloading Assets';

    $http
      .post(
        DOWNLOAD_URL.base,
        { assetFileNames: filenames },
        { 
          observe: 'response', 
          responseType: 'arraybuffer', 
          headers: { 'Content-Type': '' }  // DO NOT REMOVE - XHR Will fail during the redirect -> S3 without this
        })
      .then(function(response) {
        _self.generateFile(response, zipName + '.zip');
        if (jobList) {
          DownloadStatusService.writeCookie(jobList);
        }
        deferred.resolve(response);
      })
      .catch(function(response) {
        $rootScope.$broadcast('error', { error: response.data.error });
      })
      .finally(reset);

    return deferred.promise;
  };

  _self.downloadViewCode = function() {
    var deferred = $q.defer();
    _self.data.working = true;
    _self.data.title = 'Downloading View Codes';

    $http
      .get([REST_URL.base, '/view/export/csv'].join(''))
      .then(function(response) {
        _self.generateFile(response, 'ViewCodes.csv');
        deferred.resolve(response);
      })
      .finally(reset);

    return deferred.promise;
  };
  _self.downloadBulkJobsTemplate = function() {
    var deferred = $q.defer();
    _self.data.working = true;
    _self.data.title = 'Downloading Bulk Job Template';

    $http
      .get([REST_URL.jobs, '/bulkimport/csvtemplate'].join(''))
      .then(function(response) {
        _self.generateFile(response, 'BulkJobTemplate.csv');
        deferred.resolve(response);
      })
      .finally(reset);

    return deferred.promise;
  };
  _self.downloadBulkProductSync = function(id) {
    var deferred = $q.defer();
    _self.data.working = true;
    _self.data.title = 'Downloading Bulk Product Sync';

    $http
      .get([REST_URL.base, '/productSync/bulk/', id].join(''))
      .then(function(response) {
        _self.generateFile(response, 'BulkProductSync_Request_' + id + '.csv');
        deferred.resolve(response);
      })
      .finally(reset);

    return deferred.promise;
  };
  _self.downloadAssetRequests = function(requester, season) {
    var deferred = $q.defer();
    _self.data.working = true;
    _self.data.title = 'Downloading Asset Requests';
    var params = '?requester=' + requester;
    var fileName = requester;
    if (season && season !== '') {
      params += '&season=' + season;
      fileName += '_' + season;
    }
    fileName += '_' + moment().format('M_D_YY');

    $http
      .get([REST_URL.base, '/assets/requests', params].join(''))
      .then(function(response) {
        _self.generateFile(response, 'AssetRequests_' + fileName + '.csv');
        deferred.resolve(response);
      })
      .catch(function(response) {
        deferred.reject(response);
      })
      .finally(reset);

    return deferred.promise;
  };

  _self.downloadProductLinks = function() {
    var deferred = $q.defer();
    _self.data.working = true;
    _self.data.title = 'Downloading Product Links';

    $http
      .get([REST_URL.base, '/products/links'].join(''))
      .then(function(response) {
        _self.generateFile(
          response,
          'ICON-ProductLinks_' + moment().format('M_D_YYYY_h_mm_ss') + '.csv'
        );
        deferred.resolve(response);
      })
      .catch(function(response) {
        deferred.reject(response);
      })
      .finally(reset);

    return deferred.promise;
  };

  _self.assetRequestTemplate = function() {
    var deferred = $q.defer();

    $http
      .get([REST_URL.base, '/assets/requests/template'].join(''))
      .then(function(response) {
        _self.generateFile(response, 'AssetRequestTemplate.csv');
        deferred.resolve(response);
      })
      .catch(function(response) {
        deferred.reject(response);
      })
      .finally(reset);

    return deferred.promise;
  };

  _self.productLinkTemplate = function() {
    var deferred = $q.defer();

    $http
      .get([REST_URL.base, '/products/links/template'].join(''))
      .then(function(response) {
        _self.generateFile(response, 'ProductLinkTemplate.csv');
        deferred.resolve(response);
      })
      .catch(function(response) {
        deferred.reject(response);
      })
      .finally(reset);

    return deferred.promise;
  };

  _self.downloadAttachments = function(jobId, filename) {
    var deferred = $q.defer();
    var downloadName = filename || ['Job', jobId, 'Attachments.zip'].join('-');
    _self.data.working = true;
    _self.data.title = 'Downloading Attachments';

    $http
      .get(_self.getAttachmentUrl(jobId, filename), {
        responseType: 'arraybuffer'
      })
      .then(function(response) {
        _self.generateFile(response, downloadName);
        deferred.resolve(response);
      })
      .finally(reset);

    return deferred.promise;
  };

  _self.deleteAttachment = function(jobId, filename) {
    return $http.delete(
      [REST_URL.jobs, jobId, 'attachments', filename].join('/'),
      JobService.transformJobsConfig
    );
  };

  _self.uploadFile = function(fileElement, url) {
    var formData = new FormData(),
      file = fileElement.files[0];

    _self.data.working = true;
    _self.data.length = 1;
    _self.data.title = 'Uploading File';

    formData.append('file', file);

    return $http
      .post(
        url,
        formData,
        angular.merge({}, JobService.transformJobsConfig, {
          headers: { 'Content-Type': undefined }
        })
      )
      .finally(function() {
        reset();
        _self.data.working = false;
        fileElement.value = '';
      });
  };

  //Line Art Attachments
  _self.getLineArtUrl = function() {
    return [REST_URL.base, 'resource'].join('/');
  };

  _self.getAllLineArt = function(product) {
    var promises = [];
    promises.push(_self.getExternalLineArt(product, 'ALL'));
    promises.push(_self.getFamilyFiles(product));
    promises.push(_self.getLineArt(product));

    return $q.all(promises);
  };

  _self.getExternalLineArt = function(product, which) {
    var searchUrl = product.styleColor === undefined ? AURORA_REST_URL.searchById + product.productId
      : AURORA_REST_URL.searchByStyle + product.styleColor;
    var deferred = $q.defer();

    $http
      .get(searchUrl)
      .then(function(response) {
        $log.debug('Raw aurora:', response.data.results);
        if (response.data.results.length > 0) {
          return response.data.results[0].assets;
        }

        return [];
      })
      .then(_self.unpackAuroraSearch)
      .then(function(assets) {
        $log.debug('Unpacked aurora: ', assets);
        return _self.buildAuroraImages(product, assets, which);
      })
      .then(function(results) {
        // need to handle case where silhoutte urls don't exist either
        var promises = results.map(function(result) {
          return _self.checkAuroraUrl(result);
        });

        deferred.resolve($q.all(promises));
      })
      .catch(function(err) {
        deferred.reject({
          'status': err.status,
          'message': err.data.message,
        });
      })
      .finally(reset);

    return deferred.promise;
  };

  _self.unpackAuroraSearch = function(assets) {
    return assets.reduce(function(result, item) {
      var key = item.metadata.viewCode;

      // handle edge case where there isn't a viewCode
      if (key === undefined) {
        return result;
      }

      result[key] = item.currentUserAccess.includes('READ');
      return result;
    }, {});
  };

  _self.buildAuroraImages = function(product, assets, which) {
    var results = [];
    _self.buildAuroraPrimaryLineArtImages(product, assets, results);
    _self.buildAuroraSecondaryLineArtImages(product, assets, results);

    if (which === 'ALL') {
      _self.buildAuroraPrimaryVirtualPhotoImages(product, assets, results);
      _self.buildAuroraSecondaryVirtualPhotoImages(product, assets, results);
      _self.buildAuroraRemainingVirtualPhotoImages(product, assets, results);
    }

    return results;
  };

  _self.buildAuroraImageTypes = function(product, assets, results, viewType, keys, justOne) {
    var size = '&height=100&width=100';
    var baseLineArtUrl = product.styleColor === undefined ? AURORA_REST_URL.product + product.productId
      : AURORA_REST_URL.style + product.styleColor;
    var lineArtUrl = baseLineArtUrl + '&format=webp';
    var baseSilhouetteUrl = product.styleColor === undefined ? AURORA_REST_URL.silhouette + product.productId
      : AURORA_REST_URL.styleSilhouette + product.styleColor;
    var silhouetteUrl = baseSilhouetteUrl + '&viewType=' + viewType;
    var confidentialUrl = silhouetteUrl + '&confidential=true';
    var url;
    var assetType;

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (assets.hasOwnProperty(key)) {
        url = assets[key] ? lineArtUrl + '&viewCode=' + key
          : confidentialUrl;
        assetType = key.startsWith('LA') ? LINE_ART : VIRTUAL_PHOTO;
        // assume will be found (status === 200) and only errors come from silhouettes
        results.push({assetType: assetType, url: url, thumbnailUrl: url + size, status: 200, viewCode: key});
        delete assets[key];
        if (justOne) {
          return;
        }
      }
    }

    // if we reach here, didn't find expected viewCode and should show a silhouette
    if (viewType) {
      results.push(
        { assetType: LINE_ART,
          url: silhouetteUrl,
          thumbnailUrl: silhouetteUrl + '&height=90&width=90',
          thumbnailClass: 'gbip-asset-preview__image-silhouette'
        });
    }
  };

  // Aurora considers following viewCodes to be primary line art: VPSFH001,LASFH001,LASRZ001
  _self.buildAuroraPrimaryLineArtImages = function(product, assets, results) {
    _self.buildAuroraImageTypes(product, assets, results, 'Primary', PRIMARY_LINE_ART, true);
  };

  // Aurora considers following viewCodes to be secondary line art: VPSBH001,LASBH001
  _self.buildAuroraSecondaryLineArtImages = function(product, assets, results) {
    _self.buildAuroraImageTypes(product, assets, results, undefined, SECONDARY_LINE_ART, true);
  };

  _self.buildAuroraPrimaryVirtualPhotoImages = function(product, assets, results) {
    _self.buildAuroraImageTypes(product, assets, results, undefined, PRIMARY_VIRTUAL_PHOTO, true);
  };

  _self.buildAuroraSecondaryVirtualPhotoImages = function(product, assets, results) {
    _self.buildAuroraImageTypes(product, assets, results, undefined, SECONDARY_VIRTUAL_PHOTO, true);
  };

  _self.buildAuroraRemainingVirtualPhotoImages = function(product, assets, results) {
    var keys = Object.keys(assets).filter(function (value) {
      return !value.startsWith('PH') && !value.startsWith('WM');
    });
    _self.buildAuroraImageTypes(product, assets, results, undefined, keys, false);
  };

  _self.checkAuroraUrl = function(result) {
    var deferred = $q.defer();

    // silhouette might not exist, so need to check
    if (result.thumbnailUrl.includes('silhouette')) {
      $http.get(result.thumbnailUrl)
        .then(function() {
          deferred.resolve(result);
        })
        .catch(function(err) {
          deferred.resolve({status: err.status, thumbnailUrl: ''});
        });
    } else {
      deferred.resolve(result);
    }

    return deferred.promise;
  };

  _self.getLineArt = function(product) {
    var deferred = $q.defer();
    if (!product) {
      return deferred.resolve(false);
    }

    $http
      .get(
        [REST_URL.base, 'resources'].join('/') +
          '?type=lineArt&productId=' +
          product.productId
      )
      .then(function(response) {
        if (response.data) {
          var files = response.data.map(function(file) {
            // TODO: D6 payload differences to be addressed in ICONS-11145
            const isD6 = file.name === undefined;
            if(isD6) {
              return {
                assetType: 'attachment',
                url: REST_URL.base + `/${product.productId}/resource/${file.assetName}`,
                thumbnailUrl: REST_URL.base + `/${product.productId}/resource/thumbnail/${file.assetName}`,
                name: file.assetName,
                creator: file.createdBy,  // TODO: Get the creator NAME (not email) from D6 when its ready
                created: undefined,       // TODO: Get the created timestamp from D6 when its ready
                size: file.fileSize,
                nodeRef: undefined,       // No longer necessary when we switch over to D6
              }
            }else {
              var nodeRef = file.nodeRef;
              nodeRef = nodeRef.replace('://', '/');
              nodeRef = nodeRef.replace('workspace/SpacesStore', '');
              return {
                assetType: 'attachment',
                url: _self.getLineArtUrl() + nodeRef,
                thumbnailUrl: _self.getLineArtUrl() + '/' + 'thumbnail' + nodeRef + '?thumbnailName=' + file.thumbnailNames[0],
                name: file.name,
                creator: file.creator,
                created: file.created,
                size: file.size,
                nodeRef: file.nodeRef,
              };
            }
          });
          deferred.resolve(files);
        } else {
          deferred.resolve(false);
        }
      })
      .finally(reset);

    return deferred.promise;
  };

  _self.getFamilyFiles = function(product) {
    var familyUrl = product.styleColor === undefined ? AURORA_REST_URL.familyById + product.productId
      : AURORA_REST_URL.familyByStyle + product.styleColor;
    var filename = product.styleColor === undefined ? product.productId + ' - Family File.ai'
      : product.styleColor + ' - Family File.ai';

    var deferred = $q.defer();

    $http.get(familyUrl)
      .then(function(response) {
        $log.debug('Status for family file:', response.status);
        deferred.resolve([
          {
            assetType: 'Family File',
            url: familyUrl,
            thumbnailUrl: '',
            name: filename
          }
        ]);
      })
      .catch(function(err) {
        $log.debug('Error when getting family file:', err.message);
        deferred.resolve([]);
      });

    return deferred.promise;
  };

  _self.uploadLineArt = function(product, fileElement) {
    var deferred = $q.defer();
    var formData = new FormData(),
      file = fileElement.file.files[0];

    _self.data.working = true;
    _self.data.length = 1;
    _self.data.title = 'Uploading File';

    formData.append('file', file);
    formData.append('type', 'lineArt');
    formData.append('productId', product.productId);

    return $http
      .post(_self.getLineArtUrl(), formData, {
        headers: { 'Content-Type': undefined }
      })
      .finally(function() {
        reset();
        _self.data.working = false;
        fileElement.value = '';
      });
  };

  _self.deleteLineArt = function(product, file) {
    var fileName = file.name;
    var productId = product.productId;
    return $http.delete([REST_URL.base, productId, 'resource', fileName].join('/'));
  };

  _self.downloadLineArt = function(file) {
    $log.debug('downloadLineArt:', file);
    _self.saveFile(file.url, file.name);
  };

  _self.saveLineArt = function(file) {
    var fileName = file.url.replace(/.+productCode=/g, '').replace(/&.+/g, '');
    if (file.url.includes('productId')) {
      fileName = file.url.replace(/.+productId=/g, '').replace(/&.+/g, '');
    }
    fileName += ' Line Art - ';
    fileName += file.viewCode;

    _self.saveFile(file.url, fileName);
  };

  _self.saveFile = function(url, fileName) {
    $http
      .get(url, {
        responseType: 'arraybuffer'
      })
      .then(function(response) {
        _self.generateFile(response, fileName);
      })
      .finally(reset);
  };

  _self.init = function() {
    _self.data = {};
    reset();
  };

  _self.init();
};

module.exports = TransferService;
