'use strict';

/*jshint unused: false*/
var fileUploadGroupComponent = {
  restrict: 'E',
  replace: true,
  transclude: true,
  bindings: {
    label: '<',
    accept: '<',
    job: '<',
    type: '<',
    product: '<'
  },
  controller: /*@ngInject*/ function(
    Session,
    TransferService,
    NotificationService,
    AuthService,
    USER_ROLES,
    REST_URL,
    $q,
    $scope
  ) {
    var _self = this;
    _self.canDownload = false;
    _self.canDelete = false;

    //Data to initialize IMG Hero for SPIL lineart
    _self.imgHero = {
      active: false,
      src: ''
    };

    _self.$onInit = function() {
      _self.canDownload = AuthService.isAuthorized([
        USER_ROLES.merchOps,
        USER_ROLES.seniorImageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.studioStylist,
        USER_ROLES.digiTech,
        USER_ROLES.imageEditor,
        USER_ROLES.superUser,
        USER_ROLES.qaReviewer,
        USER_ROLES.photographer,
        USER_ROLES.productReviewer
      ]);
      _self.canDelete = AuthService.isAuthorized([
        USER_ROLES.seniorImageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.superUser,
        USER_ROLES.productReviewer
      ]);
    };

    _self.$onChanges = function(changes) {
      if (changes.product) {
        if (_self.type === 'lineArt') {
          _self.error = {
            '400': {
              active: false,
              title: '',
              body: '',
            },
            '404': {
              active: false,
              title: '',
              body: ''
            },
            '412': {
              active: false,
              title: '',
              body: ''
            },
            '500': {
              active: false,
              title:
                'There was an issue communicating with Aurora.',
              body: ''
            }
          };

          _self.product = angular.copy(changes.product.currentValue);
          if (
            _self.product &&
            (changes.product.previousValue === undefined ||
              (changes.product.isFirstChange() &&
                changes.product.currentValue.id))
          ) {
            _self.getAllLineArt();
          }
        }
      }
    };

    _self.uploadSuccess = function() {
      if (_self.type === 'lineArt') {
        _self.getAllLineArt();
      }
    };

    _self.getAllLineArt = function() {
      _self.files = [
        {
          name: 'Primary',
          thumbnailUrl: 'defaultUrl',
          url: false,
          assetType: 'Line Art'
        }
      ];
      _self.working = true;
      TransferService.getAllLineArt(_self.product)
        .then(function(response) {
          console.log('Line Art / Virtual Photos', response[0]);
          console.log('Family Files', response[1]);
          console.log('Attachments', response[2]);
          _self.files = response[0].concat(response[1]).concat(response[2]);
        })
        .catch(function(err) {
          _self.error[err.status].body = err.message;
          _self.error[err.status].active = true;
        })
        .finally(function() {
          _self.working = false;
        });
    };

    _self.deleteAttachment = function($event, file) {
      _self.working = true;

      if (_self.type === 'lineArt') {
        TransferService.deleteLineArt(_self.product, file)
          .then(_self.deleteSuccess)
          .catch(_self.deleteFailure)
          .finally(_self.deleteFinal);
      }

      $event.stopPropagation();
    };

    _self.deleteSuccess = function(response) {
      if (_self.type === 'lineArt') {
        TransferService.getAllLineArt(_self.product)
          .then(function(response) {
            _self.files = response[0].concat(response[1]);
            _self.error = undefined;
          });
      }
    };

    _self.deleteFailure = function(response) {
      NotificationService.push('Unable to delete file.', {
        response: response
      });
      if (_self.type === 'lineArt') {
        TransferService.getAllLineArt(_self.product)
          .then(function(response) {
            _self.files = response[0].concat(response[1]);
            _self.error = undefined;
          });
      }
    };

    _self.deleteFinal = function() {
      _self.working = false;
    };

    _self.downloadAttachments = function(file) {
      if ((file.assetType === 'Line Art' || file.assetType === 'Virtual Photo') && file.status !== 404 && file.status !== 412 && file.status !== 500) {
        _self.imgHero.active = true;
        _self.imgHero.src = file.url;
        _self.imgHero.name = file.name;
        _self.imgHero.viewCode = file.viewCode || 'Primary';
      } else if (
        _self.canDownload &&
        _self.type === 'lineArt' &&
        (file.assetType === 'attachment' || file.assetType === 'Family File')
      ) {
        TransferService.downloadLineArt(file);
      }
    };

    _self.onLoadError = function($event) {};
  },
  templateUrl: 'templates/partials/ui/fileUploadGroup.tpl.html'
};

module.exports = fileUploadGroupComponent;
