'use strict';

var angular = require('angular');
var moment = require('moment');

var merchStatus = {
  bindings: {
    job: '<',
    base: '<',
    relatedJobs: '<',
    onChange: '&'
  },
  require: {
    parent: '^^jobDetail'
  },
  controller: /*@ngInject*/ function(
    $interpolate,
    $element,
    AnimationService,
    META_STATUS,
    USER_ROLES,
    USER_DATA,
    AuthService,
    JobFormService,
    JobService,
    SearchService,
    StatusService,
    Session,
    UPDATE_PARAMS,
    $state,
    $window,
    APP_DATA
  ) {
    var _self = this,
      views = {
        related: 'Related {{string}} ({{length}})'
      };
    _self.error = {};

    _self.getJobStatusClass = function(status) {
      var className;

      switch (status) {
        case META_STATUS.jobPending:
        case META_STATUS.inProgress:
          className = 'active';
          break;
        case META_STATUS.problemSolve:
          className = 'problem-solve';
          break;
        default:
          className = 'inactive';
      }

      return className;
    };

    _self.getProblemSolveTask = function(model) {
      var problemSolveTask;

      model.tasks.some(function(task) {
        if (task.name === META_STATUS.problemSolve) {
          problemSolveTask = task;
          return true;
        }
      });
      return problemSolveTask;
    };

    _self.getAssetAssignee = function(model) {
      if (model.status === META_STATUS.post && model.tasks.length) {
        return model.tasks[0].assignee || null;
      }
      return null;
    };

    _self.isAdmin = AuthService.isAdmin();
    _self.previewData = {
      assign: {
        problemSolve: UPDATE_PARAMS['problem-solve'].assign,
        assets: UPDATE_PARAMS['post-prod'].assign
      },
      unassign: {
        problemSolve: UPDATE_PARAMS['problem-solve'].unassign,
        assets: UPDATE_PARAMS['post-prod'].unassign
      }
    };

    _self.confirmAssignment = function(type) {
      StatusService.setPreviewData(_self.previewData.assign[type]);
      _self.showStatusModal = true;
    };

    _self.confirmUnassignment = function(type) {
      StatusService.setPreviewData(_self.previewData.unassign[type]);
      _self.showStatusModal = true;
    };

    _self.onStatusUpdate = function($event) {
      _self.parent.update({ job: $event.jobs[0] });
      _self.showStatusModal = false;
    };

    _self.resetError = function() {
      _self.error = {};
    };

    _self.viewProduct = function() {
      var url = $state.href('detail', {
        productIdentifierType: 'by-product-id',
        productIdentifier: _self.job.product.productId,
        jobId: null,
        tab: 'deliveries'
      });
      $window.open(url, '_blank');
    };

    _self.viewMerch = function(merchId) {
      var url = $state.href('merch-management.views', {
        view: 'manage',
        q: merchId,
      });
      $window.open(url, '_blank');
    }

    _self.toggleEditMode = function(boolean) {
      if (_self.job.status === META_STATUS.jobPending) {
        _self.onChange({ $event: { type: 'newjob' } });
      } else {
        _self.editMode = _self.parent.toggleProperty(_self.editMode, boolean);

        if (_self.editMode) {
          _self.resetError();
          JobFormService.setFormData(
            _self.job,
            _self.formData,
            false,
            _self.error
          );
        }
      }
    };

    _self.toggleBase = function(boolean) {
      _self.showBase = _self.parent.toggleProperty(_self.showBase, boolean);

      var elem = $element[0].querySelector('.js-base-details');

      _self.animating = true;

      AnimationService.expand(elem, _self.showBase).then(function() {
        _self.animating = false;
      });
    };

    _self.validDate = function(input) {
      return moment(input, 'M/d/yy h:mm a', true).isValid();
    };

    _self.updateJob = function() {
      _self.working = true;

      const merchType = _self.formData.merchType.value;
      const shootType = _self.formData.shootDetail.value.shootType.code;
      if(!JobService.validateMerchTypeShootTypeCombination(merchType, shootType, (response) => { _self.error.title = response.data.error; })) {
        _self.updateJobFinally();
        return;
      }

      JobFormService.updateJob(_self.job, _self.formData)
        .then(_self.updateJobResponse)
        .catch(_self.updateJobCatch)
        .finally(_self.updateJobFinally);
    };

    _self.updateJobResponse = function(response) {
      _self.toggleEditMode(false);
      _self.parent.update({ job: response.data });
    };

    _self.updateJobCatch = function(response) {
      _self.error.title = response.data.error;
      _self.error.data = response.data.data;
    };

    _self.updateJobFinally = function() {
      _self.working = false;
    };

    _self.validate = function() {
      _self.resetError();
      JobFormService.validate(_self.formData, _self.error);
    };

    _self.isExternalImageEditor = function() {
      return USER_DATA.roles.includes('EXTERNAL_IMAGE_EDITOR');
    };

    _self.loadJobLinks = function() {
      _self.jobLinks = null;
      JobService.getJobLinks(this.job.id)
        .then(function(response) {
          _self.jobLinks = response.data;
          let styleColors = _self.jobLinks.map(jobLink => jobLink.toStyleColor);
          if (!!styleColors && styleColors.length) {
            SearchService.search({
              term: styleColors.join(' '),
              page: 0,
              size: 10000,
              sort: 'product.styleColor|asc'
            }).then(function(searchResults) {
              for (let index in _self.jobLinks) {
                let jobLink = _self.jobLinks[index];
                let matchingProducts = searchResults.data.results.filter(
                  result => {
                    return result.product.styleColor === jobLink.toStyleColor;
                  }
                );
                if (!!matchingProducts && matchingProducts.length) {
                  _self.jobLinks[index].product = matchingProducts[0].product;
                }
              }
            });
          }
        })
        .catch(function(exception) {
          _self.jobLinks = null;
          _self.jobLinkError =
            'Failed to connect to server. Please contact Support if the problem persists.';
        });
    };

    _self.$onChanges = function(changes) {
      var validAsset = false;
      if (changes.base) {
        if (_self.base) {
          _self.base = angular.copy(_self.base);
          _self.relatedStr = 'Base Product';
        }
      }

      if (changes.relatedJobs) {
        if (_self.relatedJobs) {
          _self.relatedJobs = angular.copy(_self.relatedJobs);
          _self.relatedStr = $interpolate(views.related)({
            length: _self.relatedJobs.length,
            string: _self.relatedJobs.length !== 1 ? 'jobs' : 'job'
          });
        }
      }

      if (changes.job) {
        if (!_self.job) {
          return;
        }
        _self.job = angular.copy(_self.job);

        if (!_self.jobLinks) {
          _self.loadJobLinks();
        }
        _self.jobStatusClass = _self.getJobStatusClass(_self.job.status);
        _self.problemSolveTask = _self.getProblemSolveTask(_self.job);
        _self.assetAssignee = _self.getAssetAssignee(_self.job);
        _self.assigneeSlot = StatusService.setAssigneeSlotValue(_self.job);
        
        if(_self.job.merchType && _self.job.shootType && _self.job.shootType.code) {
          JobService.getDeliveryDestinations(_self.job.merchType, _self.job.shootType.code,
            function (deliveryDestination) {
              _self.job.deliveryDestination = deliveryDestination;
            },
            function (error) {
              _self.job.deliveryDestination = 'ERROR';
            });
        } else {
          _self.job.deliveryDestination = 'N/A';
        }

        _self.job.assetJobs.some(function(assetJob) {
          validAsset = _self.job.status === META_STATUS.post;
          return validAsset;
        });

        // View permissions
        _self.isReadyToPublish =
          _self.job.status === META_STATUS.pendingRelease;
        _self.canEditJob =
          (_self.job.active || _self.isReadyToPublish) && _self.isAdmin;
        _self.canAssignProblemSolve =
          _self.job.status === META_STATUS.problemSolve &&
          _self.isAdmin &&
          !_self.problemSolveTask.assignee;
        _self.canUnassignProblemSolve =
          _self.job.status === META_STATUS.problemSolve &&
          _self.isAdmin &&
          _self.problemSolveTask.assignee;
        //check for both 'in progress' and 'in Image QA' status
        _self.canAssignAssets =
          validAsset &&
          (_self.job.status === META_STATUS.inProgress ||
            _self.job.status === META_STATUS.qa ||
            _self.job.status === META_STATUS.post) &&
          !_self.assetAssignee &&
          _self.job.assetJobs.length &&
          AuthService.isAuthorized([
            USER_ROLES.imageEditor,
            USER_ROLES.seniorImageEditor,
            USER_ROLES.studioOps,
            USER_ROLES.superUser
          ]);
        _self.canUnassignAssets =
          validAsset &&
          (_self.job.status === META_STATUS.inProgress ||
            _self.job.status === META_STATUS.qa ||
            _self.job.status === META_STATUS.post) &&
          _self.assetAssignee &&
          (AuthService.isAuthorized([
            USER_ROLES.seniorImageEditor,
            USER_ROLES.superUser
          ]) ||
            Session.compareUser(_self.assetAssignee));
        // reset form data
        _self.formData = angular.copy(JobFormService.formData);
        JobFormService.setFormData(
          _self.job,
          _self.formData,
          false,
          _self.error
        );
      }
    };

    // This should be loaded ahead of any saves for validations
    JobService.loadMerchTypeShootTypeMap();
  },
  templateUrl: 'templates/partials/detail/merchStatus.tpl.html'
};

module.exports = merchStatus;
